import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import AboutConcept from '@/components/About/Concept'
import AboutStory from '@/components/About/Story'
import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const AboutPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true}>
      <main>
        <PageTitle
          title='La Tempoについて'
          page='about'
          curtain={true}
          crumbs={crumbs}
          crumbLabel='About'
        />
        <AboutConcept />
        <AboutStory />
      </main>
    </Layout>
  )
}

export default AboutPage

export const Head: HeadFC = ({ location }) => (
  <SEO title='La Tempoについて' pathname={location.pathname} />
)
