import * as React from 'react'

import * as styles from '@/components/Parts/MediaBreak/MediaBreak.module.scss'

type Props = {
  media: 'mobile' | 'desktop'
}

const MediaBreak = ({ media }: Props) => {
  return <br className={styles[media]} />
}

export default MediaBreak
