// extracted by mini-css-extract-plugin
export var animOvalLeft = "Concept-module--animOvalLeft--lLWS3";
export var concept = "Concept-module--concept--WXQ7e";
export var container = "Concept-module--container--X7PcO";
export var decoration = "Concept-module--decoration--eX5fX";
export var description = "Concept-module--description--lFHD8";
export var fadeDown = "Concept-module--fadeDown--4XC4r";
export var fadeUp = "Concept-module--fadeUp--hx1l+";
export var flowing = "Concept-module--flowing--nDn1j";
export var lineIn = "Concept-module--lineIn--29uFp";
export var ovalImgWrap = "Concept-module--ovalImgWrap--AB4fM";
export var ovalLeftImg = "Concept-module--ovalLeftImg--V2ry6";
export var ovalLeftImgWrap = "Concept-module--ovalLeftImgWrap--zrjZg";
export var subTitle = "Concept-module--subTitle--XNUq7";
export var title = "Concept-module--title--YbB13";