import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/About/Story/Story.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import LogoSvg from '@/images/logo/logo_black.svg'

const AboutStory = () => {
  return (
    <section className={styles['story']}>
      <div className={styles['head']}>
        <h2 className={styles['title']}>はじまり</h2>
        <span className={styles['sub']}>Birth Story</span>
      </div>
      <div className={styles['grid']}>
        <div className={`${styles['birth']} ${styles['item1']}`}>
          <h3 className={styles['birthTitle']}>
            原点は<span>らてんぽ</span>
            <br />
            学生達の活動拠点。
          </h3>
          <p className={styles['birthDescription']}>
            らてんぽは、2007年7月に誕生、
            <MediaBreak media='desktop' />
            商店街の方々が鯖江市の空き店舗に開設しました。
            <br />
            <br />
            そして、そこから2022年3月までの約15年間、
            <br />
            学生活動拠点施設として若者の活動を支えてきた歴史があります。
            <br />
            また、バス待ちや、買い物帰りの一息、ご近所の常連さんたちのお喋りタイム、
            <MediaBreak media='desktop' />
            商店街でのイベントにお祭り、
            <MediaBreak media='desktop' />
            らてんぽは長らくまちの人々に憩いの場としても愛されてきました。
            <br />
            <br />
            そして、2022年3月31日16時より始まった閉館セレモニーを最後に
            <MediaBreak media='desktop' />
            その歴史に幕を下ろし、旧らてんぽは閉鎖。
            <br />
            同年9月に、「らてんぽ」から「La Tempo」へビルを変え、
            <MediaBreak media='desktop' />
            複合施設として新たに生まれ変わり、現在に至ります。
          </p>
        </div>
        <div className={`${styles['imageWrap']} ${styles['item2']}`}>
          <StaticImage
            className={styles['image']}
            src='../../../images/about/story1.png'
            alt='Story-1'
            placeholder='blurred'
          />
        </div>
        <div className={`${styles['imageWrap']} ${styles['item3']}`}>
          <StaticImage
            className={styles['image']}
            src='../../../images/about/story2.png'
            alt='Story-2'
            placeholder='blurred'
          />
        </div>
        <div className={`${styles['imageWrap']} ${styles['item4']}`}>
          <StaticImage
            className={styles['image']}
            src='../../../images/about/story3.png'
            alt='Story-3'
            placeholder='blurred'
          />
        </div>
        <div className={`${styles['imageWrap']} ${styles['item5']}`}>
          <StaticImage
            className={styles['image']}
            src='../../../images/about/story4.png'
            alt='Story-4'
            placeholder='blurred'
          />
        </div>
        <div className={`${styles['future']} ${styles['item6']}`}>
          <h3 className={styles['futureTitle']}>
            未来へ
            <MediaBreak media='mobile' />
            <span>バトン</span>をつなげ。
          </h3>
          <p className={styles['futureDescription']}>
            これまでたくさんの学生達がらてんぽを拠点にし、
            <MediaBreak media='desktop' />
            鯖江という場で活動を続けてきました。
            <br />
            <br />
            学生達は地域の方々とつながり共に活動することで、
            <MediaBreak media='desktop' />
            鯖江の未来を創る地域の担い手として育っています。
            <br />
            <br />
            新しく生まれ変わったLa Tempoはこれからも
            <br />
            そんな学生達を支える活動拠点としての機能も継続させ、
            <MediaBreak media='desktop' />
            若者とまちが互いに成長していく未来を見守っていきます。
          </p>
          <img className={styles['logo']} src={LogoSvg} alt='Logo' />
        </div>
      </div>
    </section>
  )
}

export default AboutStory
