import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/About/Concept/Concept.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import OvalLeft from '@/components/Path/OvalLeft'
import { useIntersection } from '@/hooks/useIntersection'

const AboutConcept = () => {
  const ovalImgWrapRef = React.useRef<HTMLDivElement>(null)
  const viewOvalImgWrap = useIntersection({
    target: ovalImgWrapRef,
    margin: '-40% 0%',
  })

  return (
    <section className={styles['concept']}>
      <div className={styles['ovalImgWrap']}>
        <OvalLeft />
        <div
          className={`${styles['ovalLeftImgWrap']} ${
            viewOvalImgWrap ? styles['animOvalLeft'] : undefined
          }`}
          ref={ovalImgWrapRef}
        >
          <StaticImage
            className={styles['ovalLeftImg']}
            src='../../../images/about/concept.png'
            alt='Concept'
            placeholder='blurred'
          />
        </div>
      </div>
      <div className={styles['container']}>
        <h2 className={styles['title']}>コンセプト</h2>
        <h3 className={styles['subTitle']}>
          集うビルを拠点に、
          <br />
          まちに賑わいを。
        </h3>
        <p className={styles['description']}>
          子どもの頃、そこに日常がありました。
          <br />
          <MediaBreak media='mobile' />
          まちで遊び、まちで学び、まちで買い物をする。
          <br />
          笑顔があふれ、人々が行き交う、賑やかなまち。
          <br />
          わくわくと共に、新しいコトが生まれ続けるまち。
          <MediaBreak media='desktop' />
          そんな日常にあふれたまちを創りたい。
          <br />
          <MediaBreak media='mobile' />
          たくさんの人が出会い、
          <MediaBreak media='desktop' />
          集う場を創ることできっとできる。
          <MediaBreak media='desktop' />
          そう信じているLa Tempoです。
        </p>
        <span className={styles['decoration']}>CONCEPT</span>
      </div>
    </section>
  )
}

export default AboutConcept
