// extracted by mini-css-extract-plugin
export var birth = "Story-module--birth--wnX9P";
export var birthDescription = "Story-module--birthDescription--xow2n";
export var birthTitle = "Story-module--birthTitle--v0dDa";
export var fadeDown = "Story-module--fadeDown--YEWcm";
export var fadeUp = "Story-module--fadeUp--Rz663";
export var flowing = "Story-module--flowing--fZxYO";
export var future = "Story-module--future--Zw-8a";
export var futureDescription = "Story-module--futureDescription--nl6d1";
export var futureTitle = "Story-module--futureTitle--7o-+H";
export var grid = "Story-module--grid--yrWq4";
export var head = "Story-module--head--m84Us";
export var image = "Story-module--image--LjMU9";
export var imageWrap = "Story-module--imageWrap--p2SpR";
export var item1 = "Story-module--item1--8XTEG";
export var item2 = "Story-module--item2--tzaqF";
export var item3 = "Story-module--item3--DwJ-M";
export var item4 = "Story-module--item4--p+3Hu";
export var item5 = "Story-module--item5--tJwgY";
export var item6 = "Story-module--item6--oG9PG";
export var lineIn = "Story-module--lineIn--M7f-9";
export var logo = "Story-module--logo--HPXDg";
export var story = "Story-module--story--kAn5w";
export var sub = "Story-module--sub--7y8vJ";
export var title = "Story-module--title--MMRez";